import styles from "./index.module.scss";

export default () => {
    return (
      <div className="bg-gray200 text-center">
        <ul className={styles.menu}>
          <li className={styles.selected}><a>Dashboard</a></li>
          <li><a>Contas Pagar</a></li>
          <li><a>Contas Receber</a></li>
        </ul>
      </div>
    );
};
