import React from "react";
import { Link } from "react-router-dom";
import Input from "../components/Input";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import styles from "./Login.module.scss";
import Card from "../components/Card";

export default function Index() {
    useDocumentTitle("Login");

    return (
        <>
            <div className={`flex items-center min-h-screen `}>
                <div className={styles.background}/>

                <div className="flex flex-col container mx-auto gap-10 p-5">
                    <div className="text-center px-2">
                        <h1 className="text-dark text-3xl">NKFinance</h1>
                        <h3 className="mt-4 text-dark text-sm">
                            O Melhor Sistema Financeiro Para Controlar Seu
                            Negócio. <br/>
                            
                            Para Empresas Que Pensam GRANDE!
                        </h3>
                    </div>

                    <div
                        className="flex justify-center align-center"
                    >
                        <Card>
                            <div>
                                <Input type="text" title="Login" />
                                <Input type="password" title="Senha" />

                                    <div
                                    className="my-4"
                                    >
                                    <button class="btn btn-tertiary w-full">Login</button>
                                    </div>
                                <div className="text-center link-tertiary">
                                    <Link to="/recovery">Esqueci a Senha</Link>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
