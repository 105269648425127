
function Card({ children }) {
  return (
    <div className="
    bg-card dark:bg-dark w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 py-4 rounded-lg md:rounded-xl shadow-lg
    ">
      {children}
    </div>
  );
}

export default Card;