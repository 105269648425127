import React, { createContext, useState } from 'react';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthMiddleware from './middlewares/AuthMiddleware';
import LayoutPanel from './layouts/Panel';
import Index from './pages';
import Login from './pages/login';
import Logout from './pages/logout';

export const UserContext = createContext()

export default function App() {
    const [user, setUser] = useState({});

    return (
        <UserContext.Provider value={user}>
            <BrowserRouter>
                <Routes>
                    <Route path="login" element={<Login />} />

                    <Route element={<AuthMiddleware setUser={setUser}/>}>
                        <Route path="/" element={<LayoutPanel component={Index} />} />
                        <Route path="logout" element={<Logout/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    )
};
