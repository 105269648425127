import React from "react";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function Index() {
    useDocumentTitle("Página Inicial");

    return (
        <>
            Teste
        </>
    );
}
