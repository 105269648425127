import { useQuery } from "react-query";
import { useNavigate, Outlet } from "react-router-dom";

export default function AuthMiddleware({ setUser }) {
    const navigate = useNavigate();

    const userToken = localStorage.getItem("userToken");

    const { data, isLoading, error } = useQuery("getMe", () => {
        if (!userToken) {
            return navigate("/login");
        }

        return {};
    });

    if (isLoading) {
        return (
            <div>Carregando...</div>
        );
    }

    if (error) {
        return navigate("/login");
    }

    return (
        <Outlet />
    );
}
